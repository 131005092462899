$(function(){

    let $headerBlock = $('#header_block');

    if($headerBlock.length <= 0) {
        return;
    }

    let $body = $('body');
    let $navBlock = $headerBlock.find('.nav-block');
    let $navBarBox = $navBlock.find('.navbar-box');

    $navBlock.on('click', '.button-menu-toggle', function(e){
        e.preventDefault();
        e.stopPropagation();
        $body.toggleClass('menu_active');
    });

    $(document).on('click', 'body.menu_active', function(e){
        let $target = $(e.target);

        if($target.closest('.button-menu-toggle').length > 0 || $target.closest('.navbar-box').length > 0) {
            return false;
        }

        $body.removeClass('menu_active');
    });

    $navBarBox.on('click', 'a', function(){
        $body.removeClass('menu_active');
    });

    let sticking = function(){
        let min_screen = 800;

        if($(window).width() <= min_screen) {
            $headerBlock.removeClass('stick');
            return false;
        }

        $headerBlock.addClass('stick');
    };

    sticking();
    $(window).on('scroll resize', sticking);

    $(document).on('click', '.btn_go_to', function(e){
        let $this = $(this);
        let $obj = $($this.attr('data-go').trim());

        if($obj.length <= 0) {
            window.location.href = $this.attr('href');
            return false;
        }

        e.preventDefault();
        e.stopPropagation();
        $('html, body').dequeue().stop().animate({
            scrollTop: $obj.offset().top - $headerBlock.outerHeight()
        }, 1000);
    });

    if(window.location.hash) {
        let $obj = $(window.location.hash);
        if($obj.length <= 0) {
            return false;
        }
        $('html, body').dequeue().stop().animate({
            scrollTop: $obj.offset().top - $headerBlock.outerHeight()
        }, 1000);
    }
});